import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";

import "@business-finland/wif-ui-lib/dist/style.css";
import "../styles/theme.css";

import { MGNL } from "../constants/magnolia";
import Layout from "../components/Layout";
import { WifStaticProps } from "../types/wif-next";

function MyApp({ Component, pageProps }: AppProps<WifStaticProps>) {
  const { page, title, isEmployerSite = false, navLinks } = pageProps;

  return (
    <Layout
      title={title}
      links={navLinks}
      page={page}
      isHomepage={page?.["@path"] === MGNL.ROOT_NODE}
      isEmployerSite={isEmployerSite}
    >
      <Component {...pageProps} />
    </Layout>
  );
}

export default appWithTranslation(MyApp);
