import type {
  IPageLink,
  ISwitchLinkProps,
} from "@business-finland/wif-ui-lib/dist/Navigation";
import type { SSRConfig } from "next-i18next";

export interface WifStaticProps extends SSRConfig {
  title: string;
  page: Wif.Mgnl.Page | null;
  navLinks: WifNavLinks;
  lang: string;
  templateAnnotations: Record<string, string> | null;
  isEmployerSite: boolean;
  isPreview?: boolean;
}

export interface WifNavLinks {
  primary: IPageLink[];
  secondary: IPageLink[];
  switch: ISwitchLinkProps;
}

export enum LinkSwitchableType {
  Magnolia = "magnolia",
  External = "external",
}

export type LinkSwitchableField = Wif.Mgnl.JcrNodeMetadata &
  (
    | {
    field: LinkSwitchableType.Magnolia;
    wifLinkMagnoliaPage?: Wif.Mgnl.Page;
    wifLinkAnchorTag?: string;
  }
    | {
    field: LinkSwitchableType.External;
    wifLinkExternalUrl?: string;
  }
    );
