import { Suspense, useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import NavigationUI, {
  type ILanguagePickerProps,
  type IPageLink,
  type ISwitchLinkProps,
  type ISavedItemsLinkProps,
  type SearchContentArgs,
} from "@business-finland/wif-ui-lib/dist/Navigation";

import { defaultLocale, Locale } from "../../constants/locale";
import { MGNL } from "../../constants/magnolia";
import useEmployerSite from "../../contexts/EmployerSiteContext";
import useSavedItems from "../../contexts/SaveItemsToStorageContext";
import { formatPageLink } from "../../util/magnolia";

const SearchContent = dynamic(() => import("../Search"), { ssr: false });

export interface INavigationProps {
  links: IPageLink[];
  switchLink: ISwitchLinkProps;
}

export function Navigation({ links, switchLink }: INavigationProps) {
  const { t } = useTranslation();

  const i18n = useMemo(
    () => ({
      featureArticleText: t("navigation.featureArticle"),
      readMoreText: t("navigation.readMore"),
      selectLanguageLabel: t("navigation.selectLanguage"),
    }),
    [t]
  );

  return (
    <NavigationUI
      logoHref={useLogoHref()}
      links={links}
      switchLink={switchLink}
      languagePickerOptions={useLanguagePicker()}
      savedItems={useSavedItemsLink()}
      currentPageId={useCurrentPageId(links)}
      searchContent={renderSearchContent}
      i18n={i18n}
      isSearchDisabled={false}
    />
  );
}

function renderSearchContent(args: SearchContentArgs) {
  return (
    <Suspense fallback={null}>
      <SearchContent {...args} />
    </Suspense>
  );
}

function useLogoHref(): string {
  const { isEmployerSite } = useEmployerSite();

  return formatPageLink(isEmployerSite ? MGNL.EMPLOYERS_NODE : MGNL.ROOT_NODE);
}

function useCurrentPageId(links: IPageLink[]): string | undefined {
  const { asPath } = useRouter();

  const page = useMemo(
    () => links?.find(({ href }) => asPath.startsWith(href)),
    [asPath, links]
  );

  return page?.id;
}

function useLanguagePicker(): ILanguagePickerProps | null {
  const { isEmployerSite } = useEmployerSite();
  const { locale = defaultLocale, locales = [], asPath } = useRouter();

  const getLocaleName = useCallback(
    (code: string) =>
      Object.entries(Locale).find(([, value]) => value === code)?.[0] ?? code,
    []
  );

  const languagePicker: ILanguagePickerProps = useMemo(() => {
    const availableLanguages: { code: string; label: string }[] = locales
      .filter((l) => l !== "default")
      .map((code) => ({ code, label: getLocaleName(code) }));

    return {
      currentLanguage: locale,
      availableLanguages,
      currentUrlPath: asPath,
    };
  }, [asPath, locale, locales, getLocaleName]);

  return isEmployerSite ? languagePicker : null;
}

function useSavedItemsLink(): ISavedItemsLinkProps | null {
  const { isEmployerSite } = useEmployerSite();
  const { storedItems } = useSavedItems();

  const isEmpty = storedItems.length === 0;

  return useMemo(
    () => (isEmployerSite ? null : { isEmpty, href: "/saved-items" }),
    [isEmployerSite, isEmpty]
  );
}
