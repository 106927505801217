import React, { useMemo } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import {
  ICallToActionProps,
  CallToAction,
  Logo,
  WifIcon,
} from "@business-finland/wif-ui-lib";
import {
  Footer as FooterUi,
  IFooterIconLinkProps,
} from "@business-finland/wif-ui-lib/dist/Footer";

import { Locale } from "../../constants/locale";
import useEmployerSite from "../../contexts/EmployerSiteContext";
import type { WifNavLinks } from "../../types/wif-next";

export function Footer({
  primary,
  secondary,
  switch: switchLink,
}: WifNavLinks): JSX.Element {
  const { t } = useTranslation();

  const { isEmployerSite } = useEmployerSite();

  const ctaLink = useCtaLink();
  const ctaProps: ICallToActionProps = useMemo(
    () => ({
      text: t("footer.ctaExternalLink"),
      to: ctaLink,
      isBlueBackground: true,
    }),
    [t, ctaLink]
  );

  const socialIconLinks: IFooterIconLinkProps[] = useMemo(
    () => [
      {
        icon: WifIcon.SO_ME_YOUTUBE,
        color: "white",
        url: "https://www.youtube.com/channel/UCmZLixtub8rzI1Gxkr5w5jg",
        label: "Youtube",
        width: 35.75,
        height: 25.19,
        viewBox: "8 7.2 16 17",
      },
      {
        icon: WifIcon.SO_ME_LINKEDIN,
        color: "white",
        url: isEmployerSite
          ? "https://www.linkedin.com/company/work-in-finland-kansainvalisen-rekrytoinnin-tyonantajaneuvonta/"
          : "https://linkedin.com/showcase/finland-works/",
        label: "Linkedin",
        size: 35,
      },
    ],
    [isEmployerSite]
  );

  return (
    <FooterUi
      logo={<Logo color="secondary" />}
      ctaExternalLink={<CallToAction {...ctaProps} />}
      backToTopLabel={t("footer.backToTop")}
    >
      <FooterUi.LinkList links={[...primary, switchLink]} boldText />
      <FooterUi.LinkList links={secondary} />
      <FooterUi.IconList
        iconLinks={socialIconLinks}
        followUsLabel={t("footer.followUs")}
      />
    </FooterUi>
  );
}

function useCtaLink() {
  const { locale } = useRouter();
  if (locale === Locale.Finnish) return "https://tem.fi/talent-boost";
  if (locale === Locale.Swedish) return "https://tem.fi/sv/tillvaxt-genom-internationella-talanger";
  return "https://tem.fi/en/talent-boost-en";
}
