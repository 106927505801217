import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";

import {
  getLocalStorage,
  removeLocalStorage,
  setLocalStorage,
} from "../util/localStorage";
import { LOCAL_STORAGE_SAVED_ITEMS_KEY } from "../constants/localStorageKeys";
import { ISavedItem, ISavedItemContextProps } from "../types/saved-items";

interface SavedItemsToStorageContextProviderProps {
  children: React.ReactNode;
}

const SavedItemsToStorageContext =
  createContext<ISavedItemContextProps | undefined>(undefined);

export function WifSavedItemsToStorageProvider({
  children,
}: SavedItemsToStorageContextProviderProps) {
  const [, startTransition] = useTransition();
  const [storedItems, setStoredItems] = useState<ISavedItem[]>([]);

  const getFromLocalStorage = useCallback(() => {
    const lsStoredItems = getLocalStorage(LOCAL_STORAGE_SAVED_ITEMS_KEY);

    return lsStoredItems || [];
  }, []);

  const toggleItemInLocalStorage = useCallback(
    (item: ISavedItem) => {
      let lsStoredItems = [...getFromLocalStorage()];
      if (lsStoredItems && !lsStoredItems.some((a) => a?.id === item.id)) {
        const increasedArray = [item, ...lsStoredItems];
        startTransition(() => {
          setStoredItems(increasedArray);
        });
        setLocalStorage(LOCAL_STORAGE_SAVED_ITEMS_KEY, increasedArray);
      } else {
        const decreasedArray = lsStoredItems.filter((a) => a.id !== item.id);
        startTransition(() => {
          setStoredItems(decreasedArray);
        });
        setLocalStorage(LOCAL_STORAGE_SAVED_ITEMS_KEY, decreasedArray);
      }
    },
    [getFromLocalStorage]
  );

  const isSaved = useCallback(
    (id: string | undefined) => {
      if (!id || id === undefined) {
        return false;
      }

      return storedItems.some((item: ISavedItem) => item?.id === id);
    },
    [storedItems]
  );

  const clearLocalStorage = useCallback(() => {
    removeLocalStorage(LOCAL_STORAGE_SAVED_ITEMS_KEY);
  }, []);

  useEffect(() => {
    const lsStoredItems = getFromLocalStorage();
    startTransition(() => {
      setStoredItems(lsStoredItems);
    });
  }, [getFromLocalStorage]);

  return (
    <SavedItemsToStorageContext.Provider
      value={{
        storedItems,
        toggleItemInLocalStorage,
        isSaved,
        getFromLocalStorage,
        clearLocalStorage,
      }}
    >
      {children}
    </SavedItemsToStorageContext.Provider>
  );
}

export default function useSavedItems() {
  const context = useContext(SavedItemsToStorageContext);
  if (!context) throw new Error("Cannot use this hook without Provider.");

  return context;
}
