export const getLocalStorage = (key: string) => {
  try {
    if (typeof window !== "undefined") {
      const storedDate = localStorage.getItem(key);
      return JSON.parse(storedDate!);
    }
  } catch (error) {
    return error;
  }
};

export const setLocalStorage = (key: string, item: any) => {
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(item));
  }
};

export const removeLocalStorage = (key: string) => {
  if (typeof window !== "undefined") {
    localStorage.removeItem(key);
  }
};
