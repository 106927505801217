import { useEventListener } from "@business-finland/wif-ui-lib";
import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
  useTransition,
} from "react";

import { getViewportByWindowWidth } from "../util/viewport";

const initialViewport = "xl";
const ViewportContext = createContext<Wif.Viewport>(initialViewport);

export function ViewportContextProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [, startTransition] = useTransition();
  const [viewport, setViewport] = useState<Wif.Viewport>(initialViewport);

  useEffect(
    () =>
      startTransition(() => {
        setViewport(getViewportByWindowWidth());
      }),
    []
  );

  useEventListener("resize", () =>
    startTransition(() => setViewport(getViewportByWindowWidth()))
  );

  return (
    <ViewportContext.Provider value={viewport}>
      {children}
    </ViewportContext.Provider>
  );
}

export default function useViewport() {
  return useContext(ViewportContext);
}
