import { createContext, useContext } from "react";

export interface IEmployerSiteContext {
  isEmployerSite: boolean;
}

const EmployerSiteContext = createContext<IEmployerSiteContext>({
  isEmployerSite: false,
});

export const EmployerSiteContextProvider = EmployerSiteContext.Provider;

export default function useEmployerSite() {
  return useContext(EmployerSiteContext);
}
