/* istanbul ignore file */

import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState, useTransition } from "react";

import useEmployerSite from "../../contexts/EmployerSiteContext";

export default function RouteProgressBar(): JSX.Element {
  const elementRef = useProgressElement();
  const { isEmployerSite } = useEmployerSite();

  return (
    <div
      id="progress-bar-container"
      role="none"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        display: "flex",
        height: "2px",
      }}
    >
      <div
        ref={elementRef}
        id="progress-bar"
        role="none"
        style={{
          background: isEmployerSite
            ? "var(--color-white)"
            : "var(--color-primary-700)",
          transition: "width 300ms ease-out",
        }}
      />
    </div>
  );
}

function useProgressElement(): React.RefObject<HTMLDivElement> {
  const [, startTransition] = useTransition();
  const elementRef = useRef<HTMLDivElement>(null);
  const timeout = useRef<NodeJS.Timeout>();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const startLoading = () => startTransition(() => setIsLoading(true));
    const stopLoading = () => startTransition(() => setIsLoading(false));

    router.events.on("routeChangeStart", startLoading);
    router.events.on("routeChangeComplete", stopLoading);
    router.events.on("routeChangeError", stopLoading);

    return () => {
      router.events.off("routeChangeStart", startLoading);
      router.events.off("routeChangeComplete", stopLoading);
      router.events.off("routeChangeError", stopLoading);
    };
  }, [router]);

  const setElementWidth = useCallback((width: string | number) => {
    const element = elementRef.current;
    if (!element) return;

    element.style.width = width.toString();
    element.dataset.value = width.toString();
  }, []);

  const updateWidth = useCallback(
    (ms: number) => {
      const element = elementRef.current;
      if (!element) return;

      timeout.current = setTimeout(() => {
        const width = parseFloat(element.style.width);
        const percent = !isNaN(width) ? 10 + 0.9 * width : 0;
        setElementWidth(`${percent}%`);

        updateWidth(100);
      }, ms);
    },
    [setElementWidth]
  );

  useEffect(() => {
    if (!isLoading || !elementRef.current) return;

    if (timeout.current) clearTimeout(timeout.current);

    const element = elementRef.current;
    setElementWidth("0%");
    updateWidth(300);

    return () => {
      if (timeout.current) clearTimeout(timeout.current);

      if (element.style.width === "0%") return;

      setElementWidth("100%");

      timeout.current = setTimeout(() => {
        if (element?.style.width !== "100%") return;
        setElementWidth("");
      }, 200);
    };
  }, [isLoading, updateWidth, setElementWidth]);

  return elementRef;
}
