// ---- Node environments

export const __IS_DEV_ENV__ = process.env.NODE_ENV === "development";
export const __IS_PROD_ENV__ = process.env.NODE_ENV === "production";
export const __IS_TEST_ENV__ = process.env.NODE_ENV === "test";

export const __IS_BUILD_TEST__ =
  process.env.NEXT_PUBLIC_IS_BUILD_TEST === "true";

export const __APP_HOST_PUBLIC__ = String(
  process.env.NEXT_PUBLIC_APP_HOST || ""
);

export const __APP_HOST_AUTHOR__ = String(
  process.env.NEXT_PUBLIC_APP_HOST_AUTHOR || __APP_HOST_PUBLIC__
);

// ---- Responsive device size

export enum Viewport {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
}

export enum DeviceSizes {
  "xs" = 0,
  "sm" = 600,
  "md" = 900,
  "lg" = 1024,
  "xl" = 1440,
}

export const headerHeightXs = 75;
export const headerHeightSmAndAbove = 104;

export const META_TITLE_SUFFIX = " - Work in Finland";
