import { DeviceSizes, Viewport } from "../constants/app";

export const getViewportByWindowWidth = (): Wif.Viewport => {
  if (window.innerWidth >= DeviceSizes.xl) return Viewport.xl;
  if (window.innerWidth >= DeviceSizes.lg) return Viewport.lg;
  if (window.innerWidth >= DeviceSizes.md) return Viewport.md;
  if (window.innerWidth >= DeviceSizes.sm) return Viewport.sm;
  return Viewport.xs;
};

export interface OptimiseViewportOptions {
  maxViewport?: Wif.Viewport;
  minViewport?: Wif.Viewport;
}

/**
 * Get the optimised version of viewport.
 * Formula = min(maxViewport, max(minViewport ,Viewport))
 */
export function optimiseViewport(
  viewport: Wif.Viewport,
  options: OptimiseViewportOptions = {}
): Wif.Viewport | undefined {
  const { maxViewport = Viewport.xl, minViewport = Viewport.xs } = options;
  // If viewport is limited to single option.
  if (maxViewport === minViewport) {
    return maxViewport;
  }
  // If viewport is limited to all options
  if (maxViewport === Viewport.xl && minViewport === Viewport.xs) {
    return viewport;
  }

  const minDeviceSize = DeviceSizes[minViewport];
  const maxDeviceSize = DeviceSizes[maxViewport];

  // Incorrect bounds
  if (minDeviceSize > maxDeviceSize) {
    return undefined;
  }

  // Else find the sweet spot
  const deviceSize = DeviceSizes[viewport!];
  const optimalDeviceSize = Math.min(
    Math.max(deviceSize, minDeviceSize),
    maxDeviceSize
  );
  const optimalViewport = DeviceSizes[optimalDeviceSize];

  return optimalViewport as Wif.Viewport;
}
