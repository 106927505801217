import { createContext, useContext } from "react";

const PageContentContext = createContext<Wif.Mgnl.Page | null>(null);

export const PageContentContextProvider = PageContentContext.Provider;

export default function usePageContent() {
  const context = useContext(PageContentContext);

  if (!context) {
    throw new Error("The page content is not available on this route.");
  }

  return context;
}
