export enum Locale {
  Default = "default",
  English = "en",
  Finnish = "fi",
  Swedish = "sv",
}

export const defaultLocale = Locale.English;

export const locales = [
  Locale.Finnish,
  Locale.English,
  Locale.Swedish,
] as const;
export type LocalesAll = (typeof locales)[number];
export type LocalesAllRecord = Record<LocalesAll, string>;

export const localesTalent = [Locale.English] as const;
export type LocalesTalent = (typeof localesTalent)[number];
export type LocalesTalentRecord = Record<LocalesTalent, string>;

export const localesEmployers = locales;
export type LocalesEmployers = (typeof localesEmployers)[number];
export type LocalesEmployersRecord = Record<LocalesEmployers, string>;

export const ogLocaleMap: Record<string, string> = {
  [Locale.Finnish]: "fi_FI",
  [Locale.Swedish]: "sv_SE",
  [Locale.English]: "en_GB",
};
